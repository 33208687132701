import React from "react";
import { useNavigate } from "react-router-dom";
import '../../style/responsive/mobilemessage.css';

const MobileMessage = () => {
    const navigate = useNavigate();

    const handleContinue = () => {
        // Redirect to the home page
        navigate('/home');
    };

    return (
        <div className="form_mobile_block">
            <div className='top_mobile'>
                <div className="branding">
                    <div><img src="assets/images/logo-swipple-ads.png" alt="Swipple Ads Logo" /></div>
                </div>
                {/* <div className='img-left'></div> */}
            </div>
            <div className="secondStyle">
                <div className="message-content">
                    <p>
                        Pour une meilleure expérience d’utilisation, nous vous recommandons d’utiliser <span className="bold-text">un écran d’ordinateur avec une résolution minimale de 1280x720 pixels.</span>
                    </p>
                    <p>
                        Cela vous permettra de profiter pleinement de l'interface et des fonctionnalités de l’application
                    </p>
                </div>
                <div className="button-group">
                    <button onClick={handleContinue} className="accueil-btn">
                        Accueil
                    </button>
                    <button onClick={handleContinue} className="continue-btn">
                        Continuer quand même
                    </button>
                </div>
            </div>
        </div>

    );
};

export default MobileMessage;
