import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import "../../style/Categorie.css";

function Categorie() {
  const [clickedIndices, setClickedIndices] = useState([]);
  const handleClick = (index) => {
    if (clickedIndices.includes(index)) {      
      setClickedIndices(clickedIndices.filter((i) => i !== index));
    } else {      
      setClickedIndices([...clickedIndices, index]);
    }
  };
  const categories = new Array(11).fill("Catégorie");
  return(
    <>
      <div className="container">
        <div className='d-flex justify-content-center'>
          <div className="steps">                  
            <span className="step active">Choisir la ou les catégories </span> 
            <hr className='line_step' />         
            <span className="step">Choix des sites et configuration</span>
            <hr className='line_step' />   
            <span className="step">Résumé</span>
            <hr className='line_step' />   
            <span className="step">Demande du devis</span>
          </div>
        </div>

        <div className="category-section">
          <h2>Sélectionner la ou les <span className="highlight">catégories</span> qui vous intéressent !</h2>
          <div className="categories">
          {categories.map((category, index) => (
            <div
              key={index}
              className={`category ${clickedIndices.includes(index) ? 'selected' : ''}`}
              onClick={() => handleClick(index)}
              style={{ cursor: 'pointer' }}
            >
              <span className={`badg_cate ${clickedIndices.includes(index) ? 'badg_cate_selected' : ''}`}>
                {category}
              </span>
              <img src="assets/images/categories/Rectangle 1.png" alt="" />
              {clickedIndices.includes(index) && (
                <span className='check_icon'>
                  <svg xmlns="http://www.w3.org/2000/svg" width="26" height="25" viewBox="0 0 26 25" fill="none">
                    <g clip-path="url(#clip0_1005_43646)">
                      <path d="M25.1214 12.2573C25.1214 18.6241 19.6944 23.7854 12.9999 23.7854C6.30537 23.7854 0.878418 18.6241 0.878418 12.2573C0.878418 5.89043 6.30537 0.729126 12.9999 0.729126C19.6944 0.729126 25.1214 5.89043 25.1214 12.2573ZM11.5978 18.3613L20.5912 9.80818C20.8966 9.51774 20.8966 9.04681 20.5912 8.75637L19.4852 7.70457C19.1799 7.41409 18.6847 7.41409 18.3792 7.70457L11.0448 14.6799L7.62055 11.4233C7.31517 11.1328 6.82 11.1328 6.51457 11.4233L5.40863 12.4751C5.10324 12.7655 5.10324 13.2364 5.40863 13.5269L10.4918 18.3613C10.7973 18.6517 11.2924 18.6517 11.5978 18.3613Z" fill="#8D658A"/>
                      <path d="M11.5978 18.3613L20.5912 9.80818C20.8966 9.51774 20.8966 9.04681 20.5912 8.75637L19.4852 7.70457C19.1799 7.41409 18.6847 7.41409 18.3792 7.70457L11.0448 14.6799L7.62055 11.4233C7.31517 11.1328 6.82 11.1328 6.51457 11.4233L5.40863 12.4751C5.10324 12.7655 5.10324 13.2364 5.40863 13.5269L10.4918 18.3613C10.7973 18.6517 11.2924 18.6517 11.5978 18.3613Z" fill="white"/>
                    </g>
                    <defs>
                      <clipPath id="clip0_1005_43646">
                        <rect width="25.025" height="23.8" fill="white" transform="translate(0.487305 0.3573)"/>
                      </clipPath>
                    </defs>
                  </svg>
                </span>
              )}              
            </div>
          ))}                         
          </div>
          <p><strong>{ clickedIndices.length } catégorie sélectionnée</strong></p>
          {clickedIndices.length === 0 && (
            <div className='alert-section'>
              <div className="alert">
                <svg xmlns="http://www.w3.org/2000/svg" width="29" height="29" viewBox="0 0 29 29" fill="none">
                  <g clipPath="url(#clip0_1005_45812)">
                    <path d="M28.1849 22.995C29.0821 24.5503 27.9559 26.4944 26.1638 26.4944H2.83597C1.04042 26.4944 -0.0806458 24.5472 0.814868 22.995L12.4789 2.77141C13.3766 1.21541 15.625 1.21823 16.5211 2.77141L28.1849 22.995ZM14.5 18.8138C13.265 18.8138 12.2639 19.8149 12.2639 21.0499C12.2639 22.2849 13.265 23.286 14.5 23.286C15.735 23.286 16.7361 22.2849 16.7361 21.0499C16.7361 19.8149 15.735 18.8138 14.5 18.8138ZM12.377 10.7761L12.7376 17.3873C12.7545 17.6966 13.0103 17.9388 13.3201 17.9388H15.68C15.9897 17.9388 16.2455 17.6966 16.2624 17.3873L16.623 10.7761C16.6412 10.442 16.3752 10.161 16.0405 10.161H12.9594C12.6248 10.161 12.3588 10.442 12.377 10.7761Z" fill="white"/>
                  </g>
                  <defs>
                    <clipPath id="clip0_1005_45812">
                      <rect width="28" height="28" fill="white" transform="translate(0.5 0.0499268)"/>
                    </clipPath>
                  </defs>
                </svg>
                Veuillez sélectionner au moins une catégorie pour continuer
              </div>
            </div>
          )}          
          <button className={`next-button ${clickedIndices.length != 0 ? 'button_color_yes' : 'button_color_no'} `}>Suivant</button>
        </div>
      </div>
    </>
  )
}
export default Categorie;
