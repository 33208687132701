const initialState = {
    loading: false,
    error: null,
  };
  
  export const emailReducer = (state = initialState, action) => {
    switch (action.type) {
      case 'EMAIL_SEND_REQUEST':
        return { loading: true };
      case 'EMAIL_SEND_SUCCESS':
        return { loading: false };
      case 'EMAIL_SEND_FAIL':
        return { loading: false, error: action.payload };
      default:
        return state;
    }
  };
  