import { SET_SITES_NAMES, SITE_CATEGORY, SITE_PRICES, PRICING, SITE_CATEGORY_ICONES, GET_SITE_DATA_SUCCESS, CLEAR_SITE_DATA, UPDATE_SITE_DATA, ADD_TO_CART, REMOVE_FROM_CART} from "../constants/actions";

const INITIAL_STATE = {
    sites_names: [],
    // cartItems: [],
    loadingSites: false,
    savedCommande: false,
    loadingSitePages: false,
    cartItems: [],
    sites_names_options: [],
};

function reducer(state = INITIAL_STATE, action) {
    switch (action.type) {
        case 'GET_SITES_REQUEST':
            return { ...state, loadingSites: true, error: null };
        case 'GET_SITE_PAGES_REQUEST':
            return { ...state, loadingSitePages: true, error: null };
        // case 'GET_SITES_SUCCESS':
        //     return { ...state, loadingSites: false, sites: action.payload };
        // case 'GET_SITES_FAILURE':
        //     return { ...state, loadingSites: false, error: action.payload };

        case SITE_CATEGORY :
            return {...state,sites_categories:action.payload}    
        case SITE_PRICES :
            return {...state,sites_prices:action.payload}   
        case PRICING :
            return {...state,pricing:action.payload}   
        case SITE_CATEGORY_ICONES :
            return {...state,sites_categories_icones:action.payload}   
        case SET_SITES_NAMES :
            return {...state, loadingSites: false, sites_names:action.payload}       
        case GET_SITE_DATA_SUCCESS:
            return {
                ...state,
                loadingSitePages: false,
                site_data: [...state.site_data, ...action.payload], // Concaténer les nouvelles données
            };        
        case 'LOADING_SAVE_COMMANDE_SUCCES':
            return {savedCommande: false};  
        case 'SAVE_COMMANDE_SUCCES':
            return {savedCommande: true};  
        case CLEAR_SITE_DATA:
            return {
                ...state,
                site_data: [], // Réinitialiser site_data
            }; 
        case UPDATE_SITE_DATA:
            const { index, valeur } = action.payload;
            return {
                ...state,
                site_data: state.site_data.map((site, i) => 
                    i === index ? { ...site, selectOption: valeur } : site
                )
            };
        case 'UPDATE_SITE_OPTIONS':
            return {
                ...state,
                site_data: state.site_data.map((site) =>
                site.page === action.payload.pageName
                    ? { ...site, selectedOptions: action.payload.selectedOptions }
                    : site
                ),
            };
        case 'UPDATE_SITE_DATES':
            return {
                ...state,
                site_data: state.site_data.map((site) =>
                site.page === action.payload.pageName
                    ? { ...site, dateDebut: action.payload.dateDebut, dateFin: action.payload.dateFin }
                    : site
                ),
        };
        case 'UPDATE_SITE_AFFILIATION_DATE':
            return {
                ...state,
                sites_names: state.sites_names.map((site) => 
                site.site_id === action.payload.site_id
                    ? { ...site, AffiliationDateDebut: action.payload.dateDebut, AffiliationDateFin: action.payload.dateFin }
                    : site
                ),
        };
        case 'UPDATE_SITE_AFFILIATION_OPTIONS':
            return {
                ...state,
                sites_names: state.sites_names.map((site) =>
                site.site_id === action.payload.site_id
                    ? { ...site, AffiliationOption: action.payload.selectedOptions }
                    : site
                ),
        };

        
        case 'UPDATE_SITE_DISPLAY_DATE':
            return {
                ...state,
                sites_names: state.sites_names.map((site) => 
                site.site_id === action.payload.site_id
                    ? { ...site, DisplayDateDebut: action.payload.dateDebut, DisplayDateFin: action.payload.dateFin }
                    : site
                ),
        };
        case 'UPDATE_SITE_DISPLAY_OPTIONS':
            return {
                ...state,
                sites_names: state.sites_names.map((site) =>
                site.site_id === action.payload.site_id
                    ? { ...site, DisplayOption: action.payload.selectedOptions }
                    : site
                ),
        };
        // case 'UPDATE_SITE_AFFILIATION_OPTIONS':
        //     const { siteKey2, selectedOptions } = action.payload;
        //     if (state.sites_names_options[siteKey2]) {
        //         return {
        //             ...state,
        //             sites_names_options: {
        //                 ...state.sites_names_options,
        //                 [siteKey2]: {selectedOptions: selectedOptions}
        //             }
        //         };
        //     }
    
        //     // Si le siteKey n'existe pas encore, on le crée
        //     return {
        //     ...state,
        //     sites_names_options: {
        //         ...state.sites_names_options,
        //         [siteKey2]: {selectedOptions: selectedOptions}
        //     }
        //     };

        // case ADD_TO_CART:
        //     console.log('Ajouter au panier:', action.payload);
            
        //     return {
        //         ...state,
        //         cartItems: [
        //         ...state.cartItems, // Ajoute les pages existants
        //         { ...action.payload.item, parentSiteId: action.payload.parentSiteId } // Ajoute l'élément avec les informations du parent site
        //         ]
        //     };
        case ADD_TO_CART:
            const { siteKey, site, pages } = action.payload;
            if(siteKey == "seao"){
                // Si le siteKey existe déjà, on met à jour les pages et on garde le site
                if (state.cartItems && state.cartItems[siteKey]) {
                    if (state.cartItems[siteKey][site]) {
                        return {
                          ...state,
                          cartItems: {
                            ...state.cartItems,
                            [siteKey]: {
                                ...state.cartItems[siteKey],
                              [site]: {
                                  site: site, // Conserver le site existant
                                  pages: [...new Set([...state.cartItems[siteKey][site].pages, ...pages])] // Ajout de nouvelles pages sans doublons
                              }
                            }
                          }
                        };
                    }else{
                        return {
                            ...state,
                            cartItems: {
                              ...state.cartItems,
                              [siteKey]: {
                                ...state.cartItems[siteKey],
                                [site]: {
                                    site: site,
                                    pages: pages // Ajout de nouvelles pages sans doublons
                                }
                              }
                            }
                        };
                    }
                }
          
                // Si le siteKey n'existe pas encore, on le crée
                return {
                  ...state,
                  cartItems: {
                    ...state.cartItems,
                    [siteKey]: {
                        [site]: {
                            site, // Ajout du site
                            pages // Ajout des pages
                        }
                    }
                  }
                };
            }else{
                // Si le siteKey existe déjà, on met à jour les pages et on garde le site
                var site_info
                state.sites_names.map((site) => 
                    site.site === site ? site_info = site : ''
                )
                console.log('site_info');
                console.log('site_info');
                console.log(site_info);
                
                if (state.cartItems && state.cartItems[siteKey]) {
                    return {
                        ...state,
                        cartItems: {
                            ...state.cartItems,
                            [siteKey]: {
                                ...state.cartItems[siteKey],
                                [site]: pages
                            }
                        }
                    };
                }
        
                // Si le siteKey n'existe pas encore, on le crée
                return {
                ...state,
                cartItems: {
                    ...state.cartItems,
                    [siteKey]: {
                        [site]: pages
                    }
                }
                };
            }
            case 'REMOVE_PAGE_SEAO':
                const { siteName, pageId } = action.payload;

                // Assurez-vous que le site existe dans seao
                const siteData = state.cartItems.seao[siteName];
                if (!siteData) return state;
    
                console.log("State avant modification :", state); // Log avant
                const updatedPages = siteData.pages.filter(page => page.id !== pageId);
    
                const newState = {
                    ...state,
                    cartItems: {
                        ...state.cartItems,
                        seao: {
                            ...state.cartItems.seao,
                            [siteName]: {
                                ...siteData,
                                pages: updatedPages
                            }
                        }
                    }
                };
                console.log("State après modification :", newState); // Log après
    
                return newState;


            case 'REMOVE_PAGE_AFFILIATION': {
                const siteName = action.payload;
    
                // Crée une copie de affiliation sans le site spécifié
                const updatedAffiliation = { ...state.cartItems.affiliation };
                delete updatedAffiliation[siteName];
    
                return {
                    ...state,
                    cartItems: {
                        ...state.cartItems,
                        affiliation: updatedAffiliation,
                    },
                };
            }
            case 'REMOVE_PAGE_DISPLAY': {
                const siteName = action.payload;
    
                // Crée une copie de affiliation sans le site spécifié
                const updatedDisplay = { ...state.cartItems.display };
                delete updatedDisplay[siteName];
    
                return {
                    ...state,
                    cartItems: {
                        ...state.cartItems,
                        display: updatedDisplay,
                    },
                };
            }

            case 'REMOVE_PAGE_FROM_CART':
                const { siteKey1, site1, pageToRemove } = action.payload;
                console.log('state.cartItems[siteKey1]');
                console.log(state.cartItems);
                if(siteKey1 == 'seao'){
                    const updatedCartItems = {
                        ...state.cartItems,
                        seao: {
                          ...state.cartItems.seao,
                          [site1]: {
                            ...state.cartItems.seao[site1],
                            pages: state.cartItems.seao[site1].pages.filter(
                              (page) => page.page !== pageToRemove
                            ),
                          },
                        },
                    };
                    console.log("updatedCartItems");
                    console.log(updatedCartItems);
                    
                    return {
                        ...state,
                        cartItems: updatedCartItems,
                    };
                }else if(siteKey1 == 'affiliation'){
                    const updatedCartItems = {
                        ...state.cartItems,
                        affiliation: {
                          ...state.cartItems.affiliation,
                          [site]: {
                            ...state.cartItems.affiliation[site1],
                            pages: state.cartItems.affiliation[site1].pages.filter(
                              (page) => page.page !== pageToRemove
                            ),
                          },
                        },
                    };
                    // return {
                    //     ...state,
                    //     cartItems: updatedCartItems,
                    // };
                }else if(siteKey1 == 'display'){
                    const updatedCartItems = {
                        ...state.cartItems,
                        display: {
                          ...state.cartItems.display,
                          [site]: {
                            ...state.cartItems.display[site1],
                            pages: state.cartItems.display[site1].pages.filter(
                              (page) => page.page !== pageToRemove
                            ),
                          },
                        },
                    };
                    // return {
                    //     ...state,
                    //     cartItems: updatedCartItems,
                    // };
                }


            //     if (state.cartItems[siteKey1] && state.cartItems[siteKey1][site1]) {
            //         const updatedPages = state.cartItems[siteKey1][site1].pages.filter(page => page.page !== pageToRemove);
            
            //         // If no pages left, remove the site entry
            //         if (updatedPages.length === 0) {
            //             const { [site1]: removedSite, ...remainingSites } = state.cartItems[siteKey1];
                        
            //             return {
            //                 ...state,
            //                 cartItems: {
            //                     ...state.cartItems,
            //                     [siteKey1]: remainingSites
            //                 }
            //             };
            //         }
            
            //         return {
            //             ...state,
            //             cartItems: {
            //                 ...state.cartItems,
            //                 [siteKey1]: {
            //                     ...state.cartItems[siteKey1],
            //                     [site1]: {
            //                         ...state.cartItems[siteKey1][site1],
            //                         pages: updatedPages // Update with filtered pages
            //                     }
            //                 }
            //             }
            //         };
            //     }
            // return state;
        case 'CLEAR_CARTE':
            return {
                ...state,
                cartItems: [], // Réinitialiser site_data
            }; 
        case REMOVE_FROM_CART:
            return {
                ...state,
                cartItems: state.cartItems.filter(item => item.id !== action.payload)
            };
        // case UPDATE_QTY :
        //     return {...state,data:state.data.map(
        //         (content, i) => content.id === action.payload ? {...content, qty: action.qty}: content
        //         )
        //     }
        default:
            return state;
    }
}
export default reducer;