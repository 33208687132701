import React, { useState } from "react";
import Carousel from 'react-bootstrap/Carousel';


function HomeCarousel({ setCarouselIndex }) {  
  const [index, setIndex] = useState(0);  
  const handleSelect = (selectedIndex, e) => {
    // alert(selectedIndex)
    setIndex(selectedIndex); 
    setCarouselIndex(selectedIndex); // Update parent state   
  };
  
  return (
    <>
      <Carousel activeIndex={index} onSelect={handleSelect}>        
            <Carousel.Item>          
                <div className='carrousel-div'>
                    <div className='carrousel-divs'>
                        <div className='element'>Votre logo + texte de promotion au coeur d'un contenu contextualisé et positionné en Top 10 Google.</div>
                        <div className='element'>Un lien externe populaire.</div>
                        <div className='element'>Du trafic qualifié et de grande notoriété.</div>
                        <div className='element'>Un positionnement expert</div>
                    </div>

                    <div className='carrousel-divs relative width50 ml50'>
                        <div className='carrousel-div-img1'></div>
                        <div className='carrousel-div-img3'></div>
                    </div>
                </div>
            </Carousel.Item>
            <Carousel.Item>
                <div className='carrousel-div'>
                    <div className='carrousel-divs'>
                        <div className='element'>Votre logo & CTA direct qui redirige sur votre site web ou landing dédiée avec lien UTM.</div>
                        <div className='element'>Un lien direct vers votre site pour du trafic qualifié.</div>
                        <div className='element'>Des leads uniques apportés par un tiers de confiance.</div>
                        <div className='element'>Exclusivité par secteur  ou partagé.</div>
                    </div>

                    <div className='carrousel-divs relative width50 ml50'>
                    <div className='carrousel-div-img5'></div>
                        <div className='carrousel-div-img4'></div>
                        {/* <div className='carrousel-div-img6'></div> */}
                        
                    </div>
                </div>
            </Carousel.Item>
            <Carousel.Item>                                
                <div className='carrousel-div'>
                    <div className='carrousel-divs'>
                        <div className='element'>Une visibilité maximale avec le covering.</div>
                        <div className='element'>Différentes styles de bannières possibles.</div>
                        <div className='element'>Design clés en mains !</div>
                        <div className='element'>Mémorisation et un impact redoutable</div>
                    </div>

                    <div className='carrousel-divs width45 relative'>
                        {/* <div className='carrousel-div-img7'></div> */}
                        <div className='carrousel-div-img8'></div>
                        <div className='carrousel-div-img9'></div>
                    </div>
                </div>                
          </Carousel.Item>                          
      </Carousel>

        <div className='carrousel-next'>
            <div className='carrousel-next-div'>
                <svg xmlns="http://www.w3.org/2000/svg" width="142" height="15" viewBox="0 0 142 15" fill="none">
                        <rect onClick={() => handleSelect(0)} x="0.5" y="14.75" width="14" height="39" rx="7" transform="rotate(-90 0.5 14.75)" fill={`${index == 0 ? '#8D658A':'#F2EEF3'}`} fill-opacity={`${index == 0 ? '1':'1'}`}/>
                        <rect onClick={() => handleSelect(1)} x="51.5" y="14.75" width="14" height="39" rx="7" transform="rotate(-90 51.5 14.75)" fill={`${index == 1 ? '#8D658A':'#F2EEF3'}`} fill-opacity={`${index == 1 ? '1':'1'}`}/>
                        <rect onClick={() => handleSelect(2)} x="102.5" y="14.75" width="14" height="39" rx="7" transform="rotate(-90 102.5 14.75)" fill={`${index == 2 ? '#8D658A':'#F2EEF3'}`} fill-opacity={`${index == 2 ? '1':'1'}`}/>
                </svg>                                
            </div>
        </div>
    </>
  );
}

export default HomeCarousel;
