import React from "react";

const SEOA1 = () => {
  return (
    <div className="min-h-screen flex justify-center items-center bg-gray-100">
      <div className="w-full max-w-6xl flex shadow-lg rounded-lg overflow-hidden">
        {/* Left Section */}
        <div className="w-1/2 text-white p-8" style={{ background: "#8D658A" }}>
          <div>
            <img
              src="/assets/images/ads/Group.svg"
              alt="Swipple Ads"
              className="w-48 h-auto"
            />
            <img
              src="/assets/images/ads/ads.svg"
              alt="Swipple Ads"
              className="w-48 h-auto"
              style={{
                width: "36.897px",
                height: "19.848px",
                flexShrink: "0",
                marginLeft: "160px",
              }}
            />
          </div>
          <div className="flex flex-col justify-between h-full">
            <div>
              <div style={{ width: "100%" }}>
                <span
                  style={{
                    color: "white",
                    fontSize: 36,
                    fontFamily: "Poppins",
                    fontWeight: 700,
                    wordWrap: "break-word",
                  }}
                >
                  La régie média qui
                </span>
                <span
                  style={{
                    color: "#FF8366",
                    fontSize: 36,
                    fontFamily: "Poppins",
                    fontWeight: 700,
                    wordWrap: "break-word",
                  }}
                >
                  révolutionne
                </span>
                <span
                  style={{
                    color: "white",
                    fontSize: 36,
                    fontFamily: "Poppins",
                    fontWeight: 700,
                    wordWrap: "break-word",
                  }}
                >
                  votre communication dans le monde de l’éducation.
                </span>
              </div>
              <div style={{ display: "flex", flexDirection: "row" }}>
                <div>
                  <img
                    src="/assets/images/login/image_login.png"
                    alt="Megaphone Ad"
                    style={{ height: "100%", width: "100%" }}
                  />
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "32px",
                  }}
                >
                  <div
                    style={{
                      width: "183px",
                      height: "auto",
                      paddingLeft: 10,
                      paddingRight: 10,
                      paddingTop: 5,
                      paddingBottom: 5,
                      background: "#F6F6F6",
                      borderRadius: 26,
                      justifyContent: "center",
                      alignItems: "center",
                      gap: 10,
                      display: "inline-flex",
                      position: "relative",
                      right: "40px",
                    }}
                  >
                    <div
                      style={{
                        color: "#8D658A",
                        fontSize: 12,
                        fontFamily: "Poppins",
                        fontWeight: "600",
                        wordWrap: "break-word",
                      }}
                    >
                      + de 150 sites thématiques
                    </div>
                  </div>{" "}
                  <div
                    style={{
                      width: "140px",
                      height: "auto",
                      paddingLeft: 10,
                      paddingRight: 10,
                      paddingTop: 5,
                      paddingBottom: 5,
                      background: "#F6F6F6",
                      borderRadius: 26,
                      justifyContent: "center",
                      alignItems: "center",
                      gap: 10,
                      display: "inline-flex",
                    }}
                  >
                    <div
                      style={{
                        color: "#8D658A",
                        fontSize: 12,
                        fontFamily: "Poppins",
                        fontWeight: "600",
                        wordWrap: "break-word",
                      }}
                    >
                      Ciblage ultra précis
                    </div>
                  </div>
                  <div
                    style={{
                      width: "222px",
                      height: "auto",
                      paddingLeft: 10,
                      paddingRight: 10,
                      paddingTop: 5,
                      paddingBottom: 5,
                      background: "#F6F6F6",
                      borderRadius: 26,
                      justifyContent: "center",
                      alignItems: "center",
                      gap: 10,
                      display: "inline-flex",
                    }}
                  >
                    <div
                      style={{
                        color: "#8D658A",
                        fontSize: 12,
                        fontFamily: "Poppins",
                        fontWeight: "600",
                        wordWrap: "break-word",
                      }}
                    >
                      + de 40 000 mots-clés positionnées <br />
                      en 1ère page google
                    </div>
                  </div>
                  <div
                    style={{
                      width: "219px",
                      height: "auto",
                      paddingLeft: 10,
                      paddingRight: 10,
                      paddingTop: 5,
                      paddingBottom: 5,
                      background: "#F6F6F6",
                      borderRadius: 26,
                      justifyContent: "center",
                      alignItems: "center",
                      display: "inline-flex",
                      position: "relative",
                      right: "40px",
                    }}
                  >
                    <div
                      style={{
                        color: "#8D658A",
                        fontSize: 12,
                        fontFamily: "Poppins",
                        fontWeight: "600",
                        wordWrap: "break-word",
                      }}
                    >
                      27 000 requêtes en top 10 google
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Right Section */}
        <div className="w-1/2 bg-white p-8 flex flex-col justify-between">
        <div style={{ textAlign: 'right' }}>
      <span style={{ color: '#8D658A', fontSize: 12, fontFamily: 'Poppins', fontWeight: 600, wordWrap: 'break-word' }}>
        Vous avez déjà un compte ? 
      </span>
      <span style={{ color: '#8D658A', fontSize: 12, fontFamily: 'Poppins', fontWeight: 900, wordWrap: 'break-word' }}>
        Se connecter
      </span>
    </div>
          <div style={{ width: '100%', height: '100%', position: 'relative' }}>
      <div style={{ 
        left: 0, 
        top: 0, 
        position: 'absolute', 
        flexDirection: 'column', 
        justifyContent: 'flex-start', 
        alignItems: 'flex-end', 
        display: 'inline-flex' 
      }}>
        <div style={{ 
          width: 738, 
          color: '#FF8366', 
          fontSize: 36, 
          fontFamily: 'Poppins', 
          fontWeight: 700, 
          lineHeight: '69px', 
          wordWrap: 'break-word' 
        }}>
          Ça n’a jamais été aussi simple de créer votre campagne de
        </div>
      </div>

      <div style={{ 
        paddingLeft: 40, 
        paddingRight: 40, 
        paddingTop: 10, 
        paddingBottom: 10, 
        left: 384.50, 
        top: 79, 
        position: 'absolute', 
        background: '#8D658A', 
        borderRadius: 50, 
        justifyContent: 'flex-start', 
        alignItems: 'center', 
        gap: 10, 
        display: 'inline-flex' 
      }}>
        <div style={{ 
          color: 'white', 
          fontSize: 24, 
          fontFamily: 'Poppins', 
          fontWeight: 700, 
          wordWrap: 'break-word' 
        }}>
          SEOA
        </div>
      </div>
    </div>
          <div className="flex justify-between items-center">
           
            <button
              className=" text-white px-6 py-2 rounded-full text-lg"
              style={{
                background:
                  "linear-gradient(90deg, #FF8366 -27.35%, #FCAE89 77.87%)",
              }}
            >
              Commencer
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SEOA1;
