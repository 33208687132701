import { createStore, applyMiddleware } from 'redux';
import { thunk } from 'redux-thunk'; // Named import
import logger from 'redux-logger';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import rootReducer from '../reducers';
import { emailReducer } from '../reducers/emailReducer';
const persistConfig = {
    key: 'root',
    storage: storage,
    whitelist: ["user","services"],
    email: emailReducer,
};
const pReducer = persistReducer(persistConfig, rootReducer);
const middleware = applyMiddleware(thunk, logger);
const store = createStore(pReducer, middleware);
const persistor = persistStore(store);
export { persistor, store };