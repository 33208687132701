import { combineReducers } from 'redux'
import user from './user'
import global from './global'
import services from './services'
import { emailReducer } from './emailReducer';

const rootReducer = combineReducers({
    user,
    global,
    services,
    email: emailReducer,
})

export default rootReducer;