import { BrowserRouter, Routes, Route } from 'react-router-dom'; 
import '@fontsource/poppins';
import 'bootstrap-icons/font/bootstrap-icons.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import Home from './view/Home';
import FormLogin from './view/Login/FormLogin.jsx';
import NewPasswordForm from './view/Login/NewPasswordForm';
import Validation from './view/Login/Validation.jsx';
import Categorie from './view/categories/Categorie.jsx';
import SEOA from './view/introductions/SEOA.jsx';
import SEOA1 from './view/introductions/SEOA1.jsx';
import BonCommande from './view/pdf/Boncommande'; // Import your PDF component
import ContactForm from './components/ContactForm';
import MobileMessage from './view/includes/MobileMessage';


function App() {
  return (    
    <>
      <div>
          <BrowserRouter>
              <div>
                  <Routes>
                      <Route path="/validation/:token" index element={ <Validation></Validation> } />                                                                                         
                      <Route path="/login" index element={ <FormLogin></FormLogin> } />                                                                                         
                      <Route path="/home" index element={ <Home></Home> } />                                                                                         
                      <Route path="/categorie" index element={ <Categorie></Categorie> } />                                                                                         
                      <Route path="/" index element={ <SEOA></SEOA> } />                                                                                         
                      <Route path="/SEOA1" index element={ <SEOA1></SEOA1> } />       
                      <Route path="/bon-commande" index element={<BonCommande />} />                                                                               
                      <Route path="/email" index element={<ContactForm />} />   
                      <Route path="/reset-password/:token" element={<NewPasswordForm />} />  
                      <Route path="/mobile-message" element={<MobileMessage />} /> 
                                                                                                                                                                                
                  </Routes>                            
              </div>
          </BrowserRouter>
      </div>
    </>
  );
}

export default App;
