import {SET_SITES_NAMES, SITE_CATEGORY, PRICING, SITE_CATEGORY_ICONES, SITE_PRICES, GET_SITE_DATA_SUCCESS, CLEAR_SITE_DATA,ADD_TO_CART, REMOVE_FROM_CART} from "../constants/actions"
import { apiClient } from "./api"

export const getSites = (searchedValue) => dispatch => {
    dispatch({ type: 'GET_SITES_REQUEST' });
    return apiClient().get(`/get-sheets-names?search=${searchedValue}`)
        .then((res) => {
             dispatch({type:SET_SITES_NAMES,payload:res.data.data})            
             dispatch({type:SITE_CATEGORY,payload:res.data.categories})            
             dispatch({type:SITE_CATEGORY_ICONES,payload:res.data.data_categorie_icones})            
             dispatch({type:SITE_PRICES,payload:res.data.data_prices})            
             dispatch({type:PRICING,payload:res.data.pricing})            
        })
}

export const getSiteData = (site_name, site_id, page, searchedValue, column = '', order = '') => async (dispatch) => {
    dispatch({ type: 'GET_SITE_PAGES_REQUEST' });
    try {
        const res = await apiClient().get(`/get-sheet-data?site=${site_name}&site_id=${site_id}&page=${page}&search=${searchedValue}&column=${column}&order=${order}`);
        const data = res.data.data;

        // Dispatch an action to store the data in Redux state
        dispatch({
            type: GET_SITE_DATA_SUCCESS,
            payload: data,  // Store the data
        });
    } catch (error) {
        console.error("Error fetching site data: ", error);
    }
};

export const clearSiteData = () => ({
    type: CLEAR_SITE_DATA,
});

export const updateSiteOptions = (pageName, selectedOptions) => ({
    type: 'UPDATE_SITE_OPTIONS',
    payload: { pageName, selectedOptions },
});

export const updateSiteDate = (pageName, dateDebut, dateFin) => ({
    type: 'UPDATE_SITE_DATES',
    payload: { pageName, dateDebut, dateFin },
});

export const updateSiteAffiliationDate = (site_id, dateDebut, dateFin) => ({
    type: 'UPDATE_SITE_AFFILIATION_DATE',
    payload: { site_id, dateDebut, dateFin },
});
export const updateSiteAffiliationOptions = (site_id, selectedOptions) => ({
    type: 'UPDATE_SITE_AFFILIATION_OPTIONS',
    payload: { site_id, selectedOptions },
});



export const updateSiteDisplayDate = (site_id, dateDebut, dateFin) => ({
    type: 'UPDATE_SITE_DISPLAY_DATE',
    payload: { site_id, dateDebut, dateFin },
});
export const updateSiteDisplayOptions = (site_id, selectedOptions) => ({
    type: 'UPDATE_SITE_DISPLAY_OPTIONS',
    payload: { site_id, selectedOptions },
});

export const clearCarte = () => ({
    type: "CLEAR_CARTE",
});

export const addToCart = (siteKey, site, pages) => ({
    type: ADD_TO_CART,
    payload: {
      siteKey, // ex: 'seao1', 'affiliation', etc.
      site, // ID du site
      pages // Tableau des pages à ajouter
    }
});

export const removePageSeao = (siteName, pageId) => ({
    type: "REMOVE_PAGE_SEAO",
    payload: { siteName, pageId }
});
export const removePageAffiliation = (siteName) => ({
    type: 'REMOVE_PAGE_AFFILIATION',
    payload: siteName,
});
export const removePageDisplay = (siteName) => ({
    type: 'REMOVE_PAGE_DISPLAY',
    payload: siteName,
});

export const removePageFromCart = (siteKey1, site1, pageToRemove) => {
    return {
        type: 'REMOVE_PAGE_FROM_CART',
        payload: {siteKey1, site1, pageToRemove }
    };
};
export const removeFromCart = (itemId) => {
    return {
        type: REMOVE_FROM_CART,
        payload: itemId,
    };
};


export const saveBonCommande = (data, user) => async (dispatch) => {
    dispatch({ type: 'LOADING_SAVE_COMMANDE_SUCCES' });
    try {
        const params = {
            ...data,
            user:user,
        };
        const res = await apiClient().post(`/saveBonCommande`, params);
        dispatch({ type: 'SAVE_COMMANDE_SUCCES' });
    } catch (error) {
        console.error("Error fetching site data: ", error);
    }
};

// export const saveBonCommande = (data, user) => dispatch => {
//    const params = {
//         ...data,
//         user:user,
//     };
//     return apiClient().post(`/saveBonCommande`, params).then((res) => {
//         dispatch({ type: 'SAVE_COMMANDE_SUCCES' });
//     })
// }

// export const login = async (credentials) => {
//     const client = apiClient();
//     return client.post("/login", credentials);
// };


// export const register = async (userData) => {
//     const client = apiClient();
//     return client.post("/register", userData);
// };

// export const getCollection = (id) => dispatch => {
//     return apiClient().get(`/getcollection/${id}`)
//         .then((res) => {
           
//              dispatch({type:SET_COLLECTIONS,payload:res.data})
//              return res
            
//         })
// }

// export const followBoutique = (params) => dispatch => {
//     return apiClient().post(`/followBoutique`, params)
//         .then((res) => {})
// }