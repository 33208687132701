//GLOBAL
export const TOGGLE_LOADING ="TOGGLE_LOADING";
export const IS_SUBMITED  ="IS_SUBMITED";
export const IS_CREATED  ="IS_CREATED";

//USER
export const AUTHENTICATE_USER = "AUTHENTICATE_USER"
export const SET_USER = "SET_USER"
export const LOGOUT_USER = "LOGOUT_USER"


export const SET_SITES_NAMES = "SET_SITES_NAMES"
export const GET_SITE_DATA_SUCCESS = 'GET_SITE_DATA_SUCCESS';
export const CLEAR_SITE_DATA = 'CLEAR_SITE_DATA';
export const UPDATE_SITE_DATA = 'UPDATE_SITE_DATA';
export const SITE_CATEGORY = 'SITE_CATEGORY';
export const SITE_CATEGORY_ICONES = 'SITE_CATEGORY_ICONES';
export const SITE_PRICES = 'SITE_PRICES';
export const PRICING = 'PRICING';

export const ADD_TO_CART = 'ADD_TO_CART';
export const REMOVE_FROM_CART = 'REMOVE_FROM_CART';

export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAILURE = "LOGIN_FAILURE";
export const REGISTER_SUCCESS = "REGISTER_SUCCESS";
export const REGISTER_FAILURE = "REGISTER_FAILURE";