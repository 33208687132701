import { AUTHENTICATE_USER, SET_USER, LOGOUT_USER, LOGIN_SUCCESS, LOGIN_FAILURE, REGISTER_SUCCESS, REGISTER_FAILURE } from "../constants/actions";

const INITIAL_STATE = {
    user: {},
    isAuthenticated: false,
    error: null,
};

function reducer(state = INITIAL_STATE, action) {
    switch (action.type) {
        case LOGIN_SUCCESS:
        case REGISTER_SUCCESS:
            return {
                ...state,
                user: action.payload,
                isAuthenticated: true,
                error: null
            };

        case LOGIN_FAILURE:
        case REGISTER_FAILURE:
            return {
                ...state,
                error: action.payload,
                isAuthenticated: false,
            };

        case AUTHENTICATE_USER:
            return {
                ...state,
                user: action.payload,
                isAuthenticated: true,
            };
            
        case SET_USER:
            return {
                ...state,
                user: { ...state.user, ...action.payload }
            };

        case LOGOUT_USER:
            return {
                ...state,
                user: INITIAL_STATE.user,
                isAuthenticated: false,
            };

        default:
            return state;
    }
}

export default reducer;


// import { AUTHENTICATE_USER,SET_USER,LOGOUT_USER } from "../constants/actions";

// const INITIAL_STATE = {
//     user: {},
// };

// function reducer(state = INITIAL_STATE, action) {
//     switch (action.type) {
//         case AUTHENTICATE_USER :
//             return {...state,user:action.payload}
//         case SET_USER :
//             return {...state,user:{...state.user,...action.payload}}
//         case LOGOUT_USER :
//            return {...state,user:INITIAL_STATE.user}
//         default:
//             return state;
//     }
// }
// export default reducer;