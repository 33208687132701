import axios from 'axios';
import { BASE_URL } from "../config/keys";

export const sendEmail = (emailData) => async (dispatch) => {
  try {
    dispatch({ type: 'EMAIL_SEND_REQUEST' });

    await axios.post(`${BASE_URL}/send-email`, emailData);

    dispatch({ type: 'EMAIL_SEND_SUCCESS' });
    alert('Email sent successfully!');
  } catch (error) {
    dispatch({
      type: 'EMAIL_SEND_FAIL',
      payload: error.message,
    });
    alert('Error sending email');
  }
};
