import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { sendEmail } from '../actions/emailActions';

const ContactForm = () => {
  const [email, setEmail] = useState('');
  const dispatch = useDispatch();

  const emailState = useSelector((state) => state.email);
  const { loading, error } = emailState;

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(sendEmail({ email }));
  };

  return (
    <div>
      <h2>Contact Us</h2>
      {error && <p>Error: {error}</p>}
      <form onSubmit={handleSubmit}>
        <input
          type="email"
          placeholder="Enter recipient's email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
        />
        <button type="submit" disabled={loading}>
          {loading ? 'Sending...' : 'Send Email'}
        </button>
      </form>
    </div>
  );
};

export default ContactForm;
