// NewPasswordForm.js
import React, { useState } from 'react';
import '../../style/Login.css';
import '../../style/responsive/login.css';
import { Link, useParams } from 'react-router-dom';
import { loginUser, registerUser, resetPassword } from "../../actions/user";
import { useDispatch } from 'react-redux';
import axios from 'axios';

const NewPasswordForm = () => {
    const [form, setForm] = useState({ password: '', confirmPassword: '' });
    const [errors, setErrors] = useState({});
    const [loading, setLoading] = useState(false);
    const [passwordChanged, setPasswordChanged] = useState(false);

    const { token } = useParams();
    const dispatch = useDispatch();



    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setForm((prevForm) => ({
            ...prevForm,
            [name]: value,
        }));
        setErrors((prev) => ({ ...prev, [name]: '' }));
    };

    const handleNewPasswordSubmit = async (e) => {
        e.preventDefault();
        const newErrors = {};

        // Validation logic
        if (form.password.length < 8) {
            newErrors.password = "Le mot de passe doit contenir au moins 8 caractères.";
        }

        if (Object.keys(newErrors).length > 0) {
            setErrors(newErrors);
            return;
        }

        setLoading(true);

        // Prepare the data for the API call
        const data = { token: token, password: form.password }; // Use the actual token

        try {
            // Dispatch the resetPassword action
            const response = await dispatch(resetPassword(data));
            setLoading(false);

            if (response.success) {
                setPasswordChanged(true);
                // Optionally redirect the user or show a success message
            } else {
                setErrors(response.error || 'Une erreur est survenue, veuillez réessayer plus tard.');
            }
        } catch (error) {
            setLoading(false);
            setErrors(error.error || 'Une erreur est survenue lors de la réinitialisation du mot de passe.');
        }
    };

    // const handleNewPasswordSubmit = (e) => {
    //     e.preventDefault();
    //     const newErrors = {};
    
        
    //     if (form.password.length < 8) {
    //         newErrors.password = "Le mot de passe doit contenir au moins 8 caractères.";
    //     }
    //     // if (form.password !== form.confirmPassword) {
    //     //     newErrors.confirmPassword = "Les mots de passe ne correspondent pas.";
    //     // }
    
    //     if (Object.keys(newErrors).length > 0) {
    //         setErrors(newErrors);
    //         return;
    //     }
    
    //     setLoading(true);
    
        
    //     const data = { token: token, password: form.password }; 
    
        
    //     axios.post('http://localhost:8000/api/resetPassword', data)
    //         .then(response => {
    //             setLoading(false);
    //             if (response.data.success) {
    //                 setPasswordChanged(true);
    //                 // Optionally redirect the user or show a success message
    //             } else {
    //                 setErrors(response.data.error || 'Une erreur est survenue, veuillez réessayer plus tard.');
    //             }
    //         })
    //         .catch(error => {
    //             setLoading(false);
    //             // Handle specific errors if available
    //             if (error.response && error.response.data && error.response.data.error) {
    //                 setErrors(error.response.data.error);
    //             } else {
    //                 setErrors('Une erreur est survenue lors de la réinitialisation du mot de passe.');
    //             }
    //         });
    // };
    

    // const handleNewPasswordSubmit = (e) => {
    //     e.preventDefault();
    //     const newErrors = {};

    //     // Validation logic
    //     if (form.password.length < 8) {
    //         newErrors.password = "Le mot de passe doit contenir au moins 8 caractères.";
    //     }
    //     if (form.password !== form.confirmPassword) {
    //         newErrors.confirmPassword = "Les mots de passe ne correspondent pas.";
    //     }

    //     if (Object.keys(newErrors).length > 0) {
    //         setErrors(newErrors);
    //     } else {
    //         setLoading(true);
    //         // Submit new password logic (e.g., API call)
    //         setTimeout(() => {
    //             setLoading(false);
    //             setPasswordChanged(true);
    //         }, 1000);
    //     }
    // };

    const isLoginFormFilled = form.confirmPassword &&  form.password;


    return (
        <>
            <div className="container-fluid d-flex contanairetwo" id='new_password_web'>
                <div className="left-section">
                    <div className="branding">
                        <div>
                            
                            <div>
                                <img
                                    src="/assets/images/logo-swipple-ads.png"
                                    alt="Swipple Ads Logo"
                                />
                            </div>
                        </div>
                        <div className='line'></div>
                        <p>Faites rayonner votre marque</p>
                    </div>
                    <div className="slogan">
                        <h2>La régie média <br /> <span>indépendante</span> qui <span>révolutionne</span> votre <br /> communication dans le monde de l’éducation.</h2>
                    </div>
                    <div className="section_img">
                        <img src="/assets/images/login/image_login.png" alt="" />
                    </div>
                </div>
                <div className="right-section">
                    <div id="block_new_password">
                        <div className="col-md-12">
                            {!passwordChanged ? ( // Render the form or confirmation message
                                <>

                                    <div className="form-header-login">
                                        <h2 className='text-center'>Créer votre nouveau mot de passe</h2>
                                    </div>
                                    {/* <form onSubmit={handleNewPasswordSubmit}> */}
                                    <div className="input-group row">
                                        <label className="col-md-12" htmlFor="password">Insérer votre nouveau mot de passe</label>
                                        <div className='col-md-12'>
                                            <input type="password" id="password" name="password" value={form.password} onChange={handleInputChange} placeholder='motdepasse' />
                                            {errors.password && <span className="error">{errors.password}</span>}
                                        </div>
                                    </div>
                                    <div className="input-group row">
                                        <label className="col-md-12" htmlFor="confirmPassword">Confirmation du nouveau mot de passe</label>
                                        <div className='col-md-12'>
                                            <input
                                                type="password"
                                                id="confirmPassword"
                                                name="confirmPassword"
                                                value={form.confirmPassword}
                                                onChange={handleInputChange}
                                                placeholder="motdepasse"
                                            />

                                        </div>
                                    </div>
                                    <div className="footer_textTwo marginLeftFtr">
                                        <p className='text-center w-100 mt-3'>
                                            {/* Change to Link for navigation to the login page */}
                                            <Link to="/login" className='lienSeconnecte'>
                                                <u>Se connecter</u>
                                            </Link>
                                            <span className="ou-text">ou</span>
                                            <Link to="/signup" className='lien'>
                                                <span>Créer un compte</span>
                                            </Link>
                                        </p>
                                    </div>
                                    <div className='section_submit_login mt-3'>
                                        {/* <Link to="/home" style={{ all:'unset' }} > */}
                                        {loading && <div className="width100 text-center"><img src="assets/images/loading.png" alt="" style={{ width: "80px" }} /></div>}
                                        {!loading && <button onClick={handleNewPasswordSubmit} type="button" className={`submit-btn ${isLoginFormFilled ? 'active' : ''}`} disabled={!isLoginFormFilled}>Valider</button>}
                                        {/* </Link> */}
                                    </div>
                                    {/* {loading ? <p>Loading...</p> : <button type="submit">Valider</button>} */}
                                    {/* </form> */}
                                </>
                            ) : (
                                <div className="confirmation-message text-center">

                                    <div className="form-header-login">
                                        <h2 className='text-center'>Mot de passe modifié !</h2>
                                    </div>
                                    <div className='section_submit_login mt-3'>
                                    <Link to="/login" className='lienSeconnecte'>
                                        <button className='submit-btnNotDisabled'>Se connecter</button>
                                    </Link>
                                    </div>
                                </div>
                            )}

                        </div>
                    </div>
                </div>
            </div>

            <div className='new_password_mobile'>                
                <div className='top_mobile'>
                    <div className="branding">
                        <div><img src="/assets/images/logo-swipple-ads.png" alt="Swipple Ads Logo" /></div>
                    </div>
                    <div className='img-left'></div>
                </div>                
                <div className="col-md-12">
                    {!passwordChanged ? ( 
                        <>

                            <div className="form-header-login">
                                <h2 className='text-center'>Créer votre nouveau mot de passe</h2>
                            </div> 
                            <div className='form_up'> 
                                <div className="input-group row">
                                    <label className="col-md-12" htmlFor="password">Insérer votre nouveau mot de passe</label>
                                    <div className='col-md-12'>
                                        <input type="password" id="password" name="password" value={form.password} onChange={handleInputChange} placeholder='motdepasse' />
                                        {errors.password && <span className="error">{errors.password}</span>}
                                    </div>
                                </div>
                                <div className="input-group row">
                                    <label className="col-md-12" htmlFor="confirmPassword">Confirmation du nouveau mot de passe</label>
                                    <div className='col-md-12'>
                                        <input
                                            type="password"
                                            id="confirmPassword"
                                            name="confirmPassword"
                                            value={form.confirmPassword}
                                            onChange={handleInputChange}
                                            placeholder="motdepasse"
                                        />

                                    </div>
                                </div>
                            </div>                           
                            <div className='section_submit_login mt-3'>                                                                
                                {!loading && <button onClick={handleNewPasswordSubmit} type="button" className={`submit-btn ${isLoginFormFilled ? 'active' : ''}`} disabled={!isLoginFormFilled}>Valider</button>}                                
                            </div> 
                            <div className="footer_textTwo">
                                <p className='text-center mt-3'>                                    
                                    <Link to="/login" className='lienSeconnecte'>
                                        <u>Se connecter</u>
                                    </Link>
                                    <span className="ou-text">ou</span>
                                    <Link to="/signup" className='lien'>
                                        <span>Créer un compte</span>
                                    </Link>
                                </p>
                            </div>                                                      
                        </>
                    ) : (
                        <div className="confirmation-message text-center" style={{ marginTop:'30px', marginBottom:'100px' }}>

                            <div className="form-header-login">
                                <h2 className='text-center'>Mot de passe modifié !</h2>
                            </div>
                            <div className='section_submit_login mt-3'>
                            <Link to="/login" className='lienSeconnecte'>
                                <button className='submit-btnNotDisabled'>Se connecter</button>
                            </Link>
                            </div>
                        </div>
                    )}

                </div>                
            </div>
        </>
    );
};

export default NewPasswordForm;
